<template>
  <div class="result">
    <svg v-if="status === 2" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="128" height="128"><path d="M277.9 742s167.9-294.1 465.3-465.3C575.7 577.8 277.9 742 277.9 742z" fill="#FFFFFF" p-id="1582"></path><path d="M743.1 742S575.2 447.9 277.8 276.7C445.3 577.8 743.1 742 743.1 742z" fill="#FFFFFF" p-id="1583"></path><path d="M513.2 959.8S639.3 933 746.8 817.7C841.4 716.2 868.2 483.6 868.2 151c-224.2 31.2-355-87-355-87h-2.3s-130.8 118.2-355.1 87c0 332.5 26.8 565.5 121.5 667 107.5 115.3 233.6 142 233.6 142" fill="#0EC469" p-id="1584"></path><path d="M303.6 474.4s57 132.9 145.6 132.9c85.4 0 345-302.1 345-302.1S541.9 717 457.6 717c-70.2 0-120.8-100.2-154-242.6z" fill="#FFFFFF" p-id="1585"></path></svg>
    <svg v-else-if="status > 0" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="128" height="128"><path d="M512 10.666667C234.666667 10.666667 10.666667 234.666667 10.666667 512s224 501.333333 501.333333 501.333333 501.333333-224 501.333333-501.333333S789.333333 10.666667 512 10.666667z m0 919.466666C281.6 930.133333 93.866667 742.4 93.866667 512S281.6 93.866667 512 93.866667 930.133333 281.6 930.133333 512 742.4 930.133333 512 930.133333z m-41.6-188.8h104.533333v-73.6h-104.533333v73.6z m50.133333-458.666666c-54.4-2.133333-71.466667 57.6-71.466666 57.6l26.666666 286.933333h91.733334v-5.333333L594.133333 341.333333c1.066667-1.066667-16-56.533333-73.6-58.666666z" fill="#e05e12" p-id="4167"></path></svg>
    <p v-show="status > 0">{{text}}</p>
    <button
        v-show="status > 0"
        :class="status !== 2?'failBtn':''"
        @click="goBack()">
      OK
    </button>
    <ym-checkout
        v-show="false"
        ref="checkout"
        type="payermax"
        :session="info.session"
        :currencyCode="info.currency"
        :amount="0.01"
        :iap="iap"
        @finished="onFinished"
    />
  </div>
</template>
<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {defineComponent, ref, computed, unref} from '@vue/composition-api'
import {_get} from "@/utils/axiosInstance"
import {getURLParams} from "@/utils"
import Cookies from 'js-cookie'
import {closeAll, sendFailedMsg, sendSuccessMsg} from "@/utils/bridge";
const getPayResult = (bundleId,orderId)=>{
  const url = 'https://api.funnyplay.me/ckstd/1/3/payment/status'
  return _get(url,{
    productIdentity:bundleId,
    orderId
  })
}


export default defineComponent({
  name:'Result',
  data(){
    return {
      status: 0,
      info:Cookies.get('info')
          ? JSON.parse(Cookies.get('info'))
          :{}
    }
  },
  computed: {
    text(){
      if(this.status === 2) {
        return 'Payment Success!'
      } else if(this.status>0){
        return 'Payment failed,please contact us!'
      } else {
        return ''
      }
    },
    iap() {
      const name =this.info.goodName
      const baseObj = {
        iapId: this.info.productName,
        productId: this.info.bundleId,
        itemType: '',
        itemName:name
      }
      if (name === 'Coins') {
        baseObj.itemType = 'coins_money'
      } else if (name === 'Likes') {
        baseObj.itemType = 'like_money'
        baseObj.directOrderLikeMediaId = info.directOrderLikeMediaId
        baseObj.directOrderLikeMediaUrl = info.directOrderLikeMediaUrl
      } else if (name === 'Followers') {
        baseObj.itemType = 'follow_money'
      } else if (name === 'Likes Vip') {
        baseObj.itemType = 'like_vip_monthly'
      } else if (name === 'Follower Vip') {
        baseObj.itemType = 'follow_vip_weekly'
      }
      return baseObj
    }
  },
  mounted(){
    if(this.info.system === 'android'&&process.env.NODE_ENV === 'production'){
      window.WebViewJavascriptBridge.send('disableUserFinishFalse')
    }
    this.checkPayment()
  },
  methods:{
    async checkPayment(){
      const query = getURLParams()
      const saved = Cookies.get('_requestObj')
      if(saved && this.info.bundleId){
        this.$loading('')
        const requestObj = JSON.parse(saved)
        const res  = await getPayResult(this.info.bundleId,query.orderId)
        if(res.data&&res.data.status === 2){
          await this.$refs.checkout.putOrder(requestObj)
        } else {
          this.status = 1
        }
        setTimeout(() =>{
          this.$loading.close()
        },3000)
      }
    },
    onFinished(status,data){
      console.log('onFinished',status)
      this.status = status === 'success' ? 2 : 1
      if(status === 'success'){
        this.status = 2
        sendSuccessMsg(this.info.system,data)
      } else {
        this.status = 1
        sendFailedMsg(this.info.system,101)
        $toast('payment failed')
      }
    },
    goBack(){
      Cookies.remove('info')
      closeAll(this.info.system)
    }
  }
})
</script>
<style>
.result{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
button{
  width:88px;
  height:44px;
  color: #fff;
  font-size: 16px;
  background-color: #1989fa;
  border: 1PX solid #1989fa;
  margin-top: 40px;
  border-radius:8px;
}
.failBtn{
  background-color: #e05e12;
  border: 1PX solid #e05e12;
}
p{
  font-size:18px;
  margin-top:10px;
}
</style>
